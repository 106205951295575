// import
import { useEffect } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { TITLE } from "../../config";

// assets
import style from "./Header.module.scss";

import IcoMenu from "../../assets/svgs/icon-menu.svg";
import IcoClose from "../../assets/svgs/icon-close.svg";
import { ReactComponent as LogoSymbol } from "../../assets/svgs/logo-symbol.svg";
import { ReactComponent as LogoKrFull } from "../../assets/svgs/logo-kr-full.svg";

// events
function handleToggleGnb(event) {
  event.preventDefault();

  const root = document.documentElement;
  if (!root.classList.contains("is-active-gnb")) {
    root.classList.add("is-active-gnb");
  } else {
    root.classList.remove("is-active-gnb");
  }
}

// Header
function Header() {
  const location = useLocation();
  const root = document.documentElement;

  useEffect(() => {
    if (root.classList.contains("is-active-gnb")) {
      root.classList.remove("is-active-gnb");
    }

    switch (location.pathname) {
      case "/classes/private/":
      case "/classes/couple/":
      case "/classes/group/":
        break;

      default:
        window.scrollTo(0, 0);
        break;
    }
  }, [location]);

  return (
    <header id={style.header}>
      <div className="container">
        <h1 className={style.title}>
          <Link to="/">
            <span className="blind">{TITLE}</span>
            <LogoSymbol className={style.pc} />
            <LogoKrFull className={style.mo} />
          </Link>
        </h1>

        <button
          className={style["toggle-gnb"]}
          type="button"
          aria-label="Open Global Navigation Button"
          onClick={handleToggleGnb}
        >
          <span className={style.label}>Menu</span>
          <img className={style.menu} src={IcoMenu} alt="" />
          <img className={style.close} src={IcoClose} alt="" />
        </button>

        <nav className={style.gnb}>
          <h2 className="blind">Global Navigation</h2>

          <ul role="list">
            <li role="listitem">
              <NavLink
                to="/"
                className={({ isActive }) => (isActive ? style.active : "")}
              >
                <span>Home</span>
              </NavLink>
            </li>

            <li role="listitem">
              <NavLink
                to="/about/"
                className={({ isActive }) => (isActive ? style.active : "")}
              >
                <span>About</span>
              </NavLink>
            </li>

            <li role="listitem">
              <NavLink
                to="/instructor/"
                className={({ isActive }) => (isActive ? style.active : "")}
              >
                <span>Instructors</span>
              </NavLink>
            </li>

            <li role="listitem">
              <NavLink
                to="/classes/"
                className={({ isActive }) => (isActive ? style.active : "")}
              >
                <span>Classes</span>
              </NavLink>
            </li>

            <li role="listitem">
              <NavLink
                to="/contact/"
                className={({ isActive }) => (isActive ? style.active : "")}
              >
                <span>Contact Us</span>
              </NavLink>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
}

export default Header;
